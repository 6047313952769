import { debounce } from '@patternfly/react-core'
import { ConjuntoTarefas, Peca, Relatorio, TabelaPrecos } from '../../clients'
import api, { queryClient } from '../../services/api'

const calcValorTotalPecas = (pecas: Peca[]) => {
  if (!pecas || pecas.length === 0) return 0
  return pecas
    .filter(p => !p.is_reembolsado)
    .reduce((a, b) => a + b.valor_unitario * b.quantidade, 0)
}

const calcValorTotalAtendimento = (relatorio: Relatorio, tabelaPrecos: TabelaPrecos) => {
  if (!relatorio.tempo_cobravel) return 0

  const valorHH = relatorio.apoio_remoto
    ? tabelaPrecos.valor_hh_apoio_remoto
    : tabelaPrecos.valor_hh_padrao

  const fracaoMinimaMinutos = relatorio.apoio_remoto
    ? tabelaPrecos.fracao_minima_minutos_apoio_remoto
    : tabelaPrecos.fracao_minima_minutos

  const blocosCobraveis = Math.ceil(relatorio.tempo_cobravel / fracaoMinimaMinutos)
  const fracoesEm1h = fracaoMinimaMinutos / 60
  const valorFracao = fracoesEm1h * valorHH
  let total = blocosCobraveis * valorFracao

  if (relatorio.pecas && relatorio.pecas.length > 0) {
    total = total + calcValorTotalPecas(relatorio.pecas)
  }

  return total
}

const calcTempoCobravel = (
  apoio_remoto: boolean,
  hora_inicio: string,
  hora_fim: string,
  tabelaPrecos: TabelaPrecos
) => {
  const fracaoMinimaMinutos = apoio_remoto
    ? tabelaPrecos.fracao_minima_minutos_apoio_remoto
    : tabelaPrecos.fracao_minima_minutos

  const iniDate = Date.parse(hora_inicio)
  const fimDate = Date.parse(hora_fim)

  const toleranciaMinutos = apoio_remoto ? 5 : 15

  const deltaMinutes = Math.floor((fimDate - iniDate) / (1000 * 60)) - toleranciaMinutos

  if (deltaMinutes <= toleranciaMinutos) return fracaoMinimaMinutos

  let blocosCobraveis = Math.floor(deltaMinutes / fracaoMinimaMinutos)
  if (deltaMinutes % fracaoMinimaMinutos !== 0) {
    blocosCobraveis = blocosCobraveis + 1
  }
  return blocosCobraveis * fracaoMinimaMinutos
}

const getDefaultPeca = (id: number) => {
  const peca: Peca = {
    id,
    nome: '',
    valor_unitario: 0,
    quantidade: 0,
    is_reembolsado: false,
  }
  return peca
}

const getDefaultConjTarefas = (id: number) => {
  const conj_tarefas: ConjuntoTarefas[] = [{ id, titulo: '', tarefas: [{ id: 0, descricao: '' }] }]
  return conj_tarefas
}

const putRelatorio = async (data: Relatorio) => {
  let response
  try {
    response = await api.put('relatorios', data)
  } catch (error) {
    console.log(error, response)
  }
  if (response) {
    console.log('Save OK', response.data)
  }
  await queryClient.invalidateQueries(['relatorios'])
  await queryClient.invalidateQueries(['render'])
}

const debouncePutRelatorio = debounce(putRelatorio, 400)

const sendRelat = async (relat_id?: number) => {
  if (!relat_id) return
  let data
  try {
    const response = await api.get(`/relatorios/${relat_id}/send`)
    data = response.data
  } catch (error) {
    console.error(error)
  }
  return data
}

const sendToRevisao = async (relat_id?: number, revisor_id?: number) => {
  if (!relat_id || !revisor_id) return
  let data
  const params = new URLSearchParams()
  params.append('revisor_id', revisor_id.toString())
  try {
    const response = await api.post(`/relatorios/${relat_id}/create_review?${params}`)
    data = response.data
  } catch (error) {
    console.error(error)
  }
  return data
}

export {
  calcValorTotalPecas,
  getDefaultPeca,
  getDefaultConjTarefas,
  debouncePutRelatorio,
  calcValorTotalAtendimento,
  calcTempoCobravel,
  sendRelat,
  sendToRevisao,
}
