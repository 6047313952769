import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Split,
  SplitItem,
  Stack,
  StackItem,
  Text,
  TextContent,
  TextInput,
  TextVariants,
} from '@patternfly/react-core'
import { useContext, useState } from 'react'
import { ConjuntoTarefas, Tarefa } from '../../clients'
import ModalDelete from '../shared/ModalDelete'
import Context from './store/Context'
import { ACTIONS } from './store/reducer'

const ConjuntoTarefa = ({ conjunto }: { conjunto: ConjuntoTarefas }) => {
  const { relatorio, dispatch, isLoading } = useContext(Context)
  const [isModalDeleteConjuntoOpen, setIsModalDeleteConjuntoOpen] = useState(false)
  const [isModalDeleteTarefaOpen, setIsModalDeleteTarefaOpen] = useState(false)
  const [tarefaIdToDelete, setTarefaIdToDelete] = useState<number | undefined>()

  if (isLoading) return <></>

  return (
    <>
      <ModalDelete
        title='Remover Conjunto de Tarefas'
        isModalOpen={isModalDeleteConjuntoOpen}
        handleModalToggle={() => setIsModalDeleteConjuntoOpen(!isModalDeleteConjuntoOpen)}
        handleSubmit={() =>
          dispatch({
            type: ACTIONS.CONJUNTO_TAREFAS_DELETE,
            payload: { conjuntoTarefas: conjunto },
          })
        }
      />
      <ModalDelete
        title='Remover Tarefa'
        isModalOpen={isModalDeleteTarefaOpen}
        handleModalToggle={() => setIsModalDeleteTarefaOpen(!isModalDeleteTarefaOpen)}
        handleSubmit={() =>
          dispatch({
            type: ACTIONS.TAREFA_DELETE,
            payload: { id: tarefaIdToDelete, conjuntoTarefas: conjunto },
          })
        }
      />
      <Card>
        <CardTitle>
          <TextInput
            placeholder='Adicionar Titulo / Local / Computador / Servidor:'
            value={conjunto.titulo}
            validated={conjunto.titulo.length < 400 ? 'default' : 'error'}
            type='text'
            onChange={(text: string) =>
              dispatch({
                type: ACTIONS.CONJUNTO_TAREFAS_UPDATE_TITULO,
                payload: { conjuntoTarefas: conjunto, prop: { name: 'titulo', value: text } },
              })
            }
            aria-label='titulo_conjunto_tarefa'
          />
        </CardTitle>
        <CardBody>
          <Stack hasGutter>
            {conjunto.tarefas
              ?.sort((a: Tarefa, b: Tarefa) => a.id - b.id)
              .map((t, i) => (
                <StackItem key={i}>
                  <Split hasGutter>
                    <SplitItem isFilled>
                      <TextInput
                        id={`tarefa_${conjunto.id}_${t.id}`}
                        placeholder='Descrever item'
                        value={t.descricao}
                        validated={t.descricao.length < 400 ? 'default' : 'error'}
                        type='text'
                        aria-label='descrever-etapa'
                        onChange={(text: string) =>
                          dispatch({
                            type: ACTIONS.TAREFA_UPDATE,
                            payload: {
                              conjuntoTarefas: conjunto,
                              id: t.id,
                              prop: { name: 'descricao', value: text },
                            },
                          })
                        }
                      />
                    </SplitItem>
                    <SplitItem>
                      <Button
                        variant='secondary'
                        isDanger
                        isDisabled={i === 0}
                        onClick={() => {
                          setTarefaIdToDelete(t.id)
                          setIsModalDeleteTarefaOpen(!isModalDeleteTarefaOpen)
                        }}
                      >
                        Remover
                      </Button>
                    </SplitItem>
                  </Split>
                </StackItem>
              ))}
          </Stack>
        </CardBody>
        <CardFooter>
          <Split hasGutter>
            <SplitItem>
              <Button
                variant='primary'
                onClick={() =>
                  dispatch({ type: ACTIONS.TAREFA_ADD, payload: { conjuntoTarefas: conjunto } })
                }
              >
                Adicionar tarefa
              </Button>
            </SplitItem>
            <SplitItem>
              <Button
                variant='danger'
                isDisabled={relatorio?.conjuntos_tarefas?.length === 1}
                onClick={() => setIsModalDeleteConjuntoOpen(!isModalDeleteConjuntoOpen)}
              >
                Remover bloco
              </Button>
            </SplitItem>
          </Split>
        </CardFooter>
      </Card>
    </>
  )
}

const DescricaoServico = () => {
  const { relatorio, dispatch, isLoading } = useContext(Context)

  if (isLoading) return <></>

  return (
    <Stack hasGutter>
      <StackItem>
        <TextContent>
          <Text component={TextVariants.h2}>Descrição do Serviço</Text>
        </TextContent>
      </StackItem>
      {relatorio.conjuntos_tarefas
        ?.sort((a, b) => a.id - b.id)
        ?.map((ct, i) => (
          <StackItem key={i}>
            <ConjuntoTarefa conjunto={ct} />
          </StackItem>
        ))}
      <StackItem>
        <Button
          variant='primary'
          onClick={() => dispatch({ type: ACTIONS.CONJUNTO_TAREFAS_ADD, payload: {} })}
        >
          Adicionar bloco
        </Button>
      </StackItem>
    </Stack>
  )
}

export default DescricaoServico
