import { Modal } from '@patternfly/react-core'
import { Dispatch, SetStateAction, useContext } from 'react'
import { useQuery } from '@tanstack/react-query'
import api from '../../services/api'
import Context from './store/Context'

const queryOptions = {
  refetchOnWindowFocus: true,
  retry: false,
  staleTime: 1000 * 5,
}

interface IPropType {
  isModalOpen: boolean
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
}

const ModalVisualizar = ({ isModalOpen, setIsModalOpen }: IPropType) => {
  const { isLoading, relatorio } = useContext(Context)


  const { isLoading: renderIsLoading, data: render } = useQuery({
    queryKey: ['render'],
    queryFn: () => api.get(`relatorios/${relatorio.id}/render`).then(r => r.data),
    enabled: !isLoading && !!relatorio.id,
    ...queryOptions,
  })

  if (isLoading || renderIsLoading) return <></>

  return (
    <Modal
      onClose={() => setIsModalOpen(false)}
      title='Visualizar'
      aria-label='modal-visualizar'
      isOpen={isModalOpen}
    >
      <div dangerouslySetInnerHTML={{ __html: render }} />
    </Modal>
  )
}

export default ModalVisualizar
