import {
  Card,
  CardBody,
  CardFooter,
  Checkbox,
  Divider,
  Hint,
  HintBody,
  NumberInput,
  Split,
  SplitItem,
  Stack,
  StackItem,
  Text,
  TextArea,
  TextContent,
  TextInput,
  TextVariants,
} from '@patternfly/react-core'
import { useContext, useState } from 'react'
import GlobalContext from '../../../contexts/GlobalContext'

import { formatBRL, IntegerToBRL } from '../../../utils/currency'

import DatetimePicker from '../../../components/calendar/DatetimePicker'

import ModalDelete from '../../shared/ModalDelete'
import MonetaryInput from '../../shared/MonetaryInput'
import Context from '../store/Context'
import { ACTIONS } from '../store/reducer'
import SelectFormaPagamento from './SelectFormaPagamento'
import SelectTabelaPrecos from './SelectTabelaPrecos'
import TimeRangePicker from './TimeRangePicker'

const Cobranca = () => {
  const { isLoading, dispatch, relatorio } = useContext(Context)
  const { clientes, precos } = useContext(GlobalContext)
  const [isModalHorarioManualOpen, setIsModalHorarioManualOpen] = useState(false)
  const [isModalTempoCobravelOpen, setIsModalTempoCobravelOpen] = useState(false)
  const [isModalOrcamentoPrevioOpen, setIsModalOrcamentoPrevioOpen] = useState(false)

  if (isLoading) return null

  const cliente = clientes.get(relatorio?.cliente_id || -1)
  const tabelaPrecos = precos.get(relatorio.tabela_precos_id || -1)

  const {
    is_tempo_cobravel_manual,
    is_orcamento_previo,
    orcamento_previo_texto,
    tempo_cobravel,
    tempo_cobravel_just,
    is_horario_manual,
    horario_manual_texto,
    apoio_remoto,
    valor_total_atendimento,
    hora_inicio,
  } = relatorio

  const fracaoMinimaMinutos = apoio_remoto
    ? tabelaPrecos?.fracao_minima_minutos_apoio_remoto
    : tabelaPrecos?.fracao_minima_minutos

  return (
    <Stack hasGutter>
      <StackItem>
        <TextContent>
          <Text component={TextVariants.h2}>Cobrança</Text>
        </TextContent>
      </StackItem>
      <StackItem>
        <Card>
          <CardBody>
            <Stack hasGutter>
              <StackItem>
                <Hint>
                  <HintBody>
                    <TextContent>
                      <Text component={TextVariants.p}>
                        {cliente?.instrucoes_cobranca ?? 'Escolha o cliente'}
                      </Text>
                    </TextContent>
                  </HintBody>
                </Hint>
                <Divider />
              </StackItem>
              <StackItem>
                <Checkbox
                  id='is_horario_manual_Checkbox'
                  label='Definir horário de entrada e saída através do calendário'
                  isChecked={!is_horario_manual}
                  onChange={() => setIsModalHorarioManualOpen(!isModalHorarioManualOpen)}
                />
                <ModalDelete
                  title={is_horario_manual ? 'Desabilitar' : 'Habilitar Horário Manual'}
                  isModalOpen={isModalHorarioManualOpen}
                  handleModalToggle={() => setIsModalHorarioManualOpen(!isModalHorarioManualOpen)}
                  handleSubmit={() => dispatch({ type: ACTIONS.MANUAL_PERIOD_TOGGLE, payload: {} })}
                  alertMessage={
                    is_horario_manual
                      ? `
                Atenção! Habilitar definição do bloco de entrada e saída manual terá as seguintes consequências:
                1) O horário já escolhido será perdido;
                2) O tempo cobrável será zerado e deverá ser redefinido manualmente;
                3) O valor total do atendimento será alterado;
                4) Você deverá escrever um texto para substituir o bloco de entrada e saída;
                5) O Administrador do sistema será notificado.
                `
                      : `
                Atenção! Desabilitar definição do bloco de entrada e saída manual terá as seguintes consequências:
                1) Todo o texto digitado no bloco será perdido;
                2) O valor definido na data de referência será perdido;
                3) O tempo cobrável será resetado para 'automático' e o valor digitado manualmente será perdido;
                4) O valor total do atendimento será alterado.
                    `
                  }
                  removeButtonLabel={is_horario_manual ? 'Habilitar' : 'Desabilitar'}
                />
              </StackItem>
              {is_horario_manual ? (
                <>
                  <StackItem>
                    <TextArea
                      placeholder='Bloco de texto que aparecerá no lugar da data e hora.'
                      value={horario_manual_texto ?? ''}
                      validated={
                        horario_manual_texto && horario_manual_texto.length < 400
                          ? 'default'
                          : 'error'
                      }
                      type='text'
                      aria-label='horario_manual_texto'
                      onChange={value =>
                        dispatch({
                          type: ACTIONS.UPDATE_PROP,
                          payload: { prop: { name: 'horario_manual_texto', value } },
                        })
                      }
                    />
                  </StackItem>
                  <StackItem>
                    Data de Referência
                    <DatetimePicker
                      value={hora_inicio ? new Date(hora_inicio) : new Date()}
                      onChange={newDate =>
                        dispatch({
                          type: ACTIONS.MANUAL_PERIOD_DATA_REFERENCIA_UPDATE,
                          payload: { periodReferencia: newDate },
                        })
                      }
                      variant='date'
                    />
                  </StackItem>
                </>
              ) : (
                <StackItem>
                  <TimeRangePicker />
                </StackItem>
              )}
              {!is_orcamento_previo && (
                <>
                  <ModalDelete
                    title={
                      is_tempo_cobravel_manual ? 'Desabilitar' : 'Habilitar Tempo Cobrável Manual'
                    }
                    isModalOpen={isModalTempoCobravelOpen}
                    handleModalToggle={() => setIsModalTempoCobravelOpen(!isModalTempoCobravelOpen)}
                    handleSubmit={() =>
                      dispatch({
                        type: ACTIONS.TEMPO_COBRAVEL_AUTO_TOGGLE,
                        payload: { tabelaPrecos },
                      })
                    }
                    alertMessage={
                      is_tempo_cobravel_manual
                        ? `
                Atenção! Habilitar tempo cobrável manual terá as seguintes consequências:
                1) O tempo cobrável será zerado e deverá ser redefinido manualmente;
                2) O valor total do atendimento será alterado;
                3) O Administrador do sistema será notificado.
   
                `
                        : `
                Atenção! Desabilitar tempo cobrável manual terá as seguintes consequências:
                1) O tempo cobrável será resetado para 'automático' e o valor digitado manualmente será perdido;
                2) O valor total do atendimento será alterado;
                3) O texto digitado na justificativa será perdido.
                    `
                    }
                    removeButtonLabel={is_horario_manual ? 'Habilitar' : 'Desabilitar'}
                  />
                  <StackItem>
                    <Checkbox
                      id='tempo_cobravel_personalizado_Checkbox'
                      label='Tempo cobrável automático'
                      isChecked={!is_tempo_cobravel_manual}
                      onChange={() => setIsModalTempoCobravelOpen(!isModalTempoCobravelOpen)}
                      name='check_tempo_cobravel_personalizado_Checkbox'
                    />
                  </StackItem>
                  <StackItem isFilled>
                    Tempo cobrável{' '}
                    <NumberInput
                      value={tempo_cobravel}
                      onChange={(e: any) =>
                        dispatch({
                          type: ACTIONS.TEMPO_COBRAVEL_UPDATE,
                          payload: {
                            tabelaPrecos,
                            prop: { name: 'tempo_cobravel', value: e.target.value },
                          },
                        })
                      }
                      onMinus={() => {
                        if (!tempo_cobravel || tempo_cobravel <= (fracaoMinimaMinutos ?? 15)) return
                        const value = tempo_cobravel - (fracaoMinimaMinutos ?? 15)
                        dispatch({
                          type: ACTIONS.TEMPO_COBRAVEL_UPDATE,
                          payload: {
                            prop: { name: 'tempo_cobravel', value },
                            tabelaPrecos,
                          },
                        })
                      }}
                      onPlus={() =>
                        dispatch({
                          type: ACTIONS.TEMPO_COBRAVEL_UPDATE,
                          payload: {
                            prop: {
                              name: 'tempo_cobravel',
                              value: (tempo_cobravel ?? 0) + (fracaoMinimaMinutos ?? 15),
                            },
                            tabelaPrecos,
                          },
                        })
                      }
                      inputName='input_tempo_cobravel'
                      inputAriaLabel='input_tempo_cobravel'
                      minusBtnAriaLabel='input_tempo_cobravel_minus'
                      plusBtnAriaLabel='input_tempo_cobravel_plus'
                      unitPosition='after'
                      unit='Minutos'
                      className='pf-u-my-sm'
                      min={1}
                      widthChars={5}
                      isDisabled={!is_tempo_cobravel_manual}
                    />
                    {is_tempo_cobravel_manual && (
                      <StackItem>
                        <TextArea
                          placeholder='Justificativa tempo cobrável personalizado'
                          value={tempo_cobravel_just ?? ''}
                          validated={
                            tempo_cobravel_just && tempo_cobravel_just.length < 400
                              ? 'default'
                              : 'error'
                          }
                          type='text'
                          aria-label='tempo_cobravel_justificativa'
                          onChange={value =>
                            dispatch({
                              type: ACTIONS.UPDATE_PROP,
                              payload: {
                                prop: { name: 'tempo_cobravel_just', value },
                              },
                            })
                          }
                        />
                      </StackItem>
                    )}
                  </StackItem>
                </>
              )}
              <StackItem>
                <ModalDelete
                  title={is_orcamento_previo ? 'Desabilitar' : 'Habilitar Orçamento Prévio'}
                  isModalOpen={isModalOrcamentoPrevioOpen}
                  handleModalToggle={() =>
                    setIsModalOrcamentoPrevioOpen(!isModalOrcamentoPrevioOpen)
                  }
                  handleSubmit={() =>
                    dispatch({ type: ACTIONS.ORCAMENTO_PREVIO_TOGGLE, payload: {} })
                  }
                  alertMessage={
                    is_tempo_cobravel_manual
                      ? `
                Atenção! Habilitar o modo "Orçamento prévio" terá as seguintes consequências:
                1) Você descreverá a cobrança via orçamento prévio;
                2) Você deverá definir o valor total do serviço manualmente;
                3) O administrador do sistema será notificado.   
                `
                      : `
                Atenção! Desabilitar o modo "Orçamento prévio" terá as seguintes consequências:
                1) O texto digitado na descrição do orçamento prévio será perdido;
                2) O valor definido será perdido;
                3) O valor total do atendimento será zerado e você deverá definir o valor total do serviço manualmente;
                    `
                  }
                  removeButtonLabel={is_horario_manual ? 'Habilitar' : 'Desabilitar'}
                />
                <Checkbox
                  id='is_orcamento_previo_switch'
                  label='Orçamento prévio'
                  isChecked={is_orcamento_previo}
                  onChange={() => setIsModalOrcamentoPrevioOpen(!isModalOrcamentoPrevioOpen)}
                />
              </StackItem>
              {is_orcamento_previo ? (
                <>
                  <StackItem>
                    Texto orçamento prévio
                    <TextArea
                      placeholder='Descreva a cobrança via orçamento prévio'
                      value={orcamento_previo_texto ?? ''}
                      validated={
                        orcamento_previo_texto && orcamento_previo_texto.length < 400
                          ? 'default'
                          : 'error'
                      }
                      type='text'
                      aria-label='orçamento_prévio_texto'
                      onChange={value =>
                        dispatch({
                          type: ACTIONS.UPDATE_PROP,
                          payload: { prop: { name: 'orcamento_previo_texto', value } },
                        })
                      }
                    />
                  </StackItem>
                  <StackItem>
                    Valor total do serviço&nbsp;
                    <MonetaryInput
                      value={valor_total_atendimento ?? 0}
                      onChange={value => {
                        dispatch({
                          type: ACTIONS.UPDATE_PROP,
                          payload: {
                            prop: { name: 'valor_total_atendimento', value },
                          },
                        })
                      }}
                      className='pf-u-my-sm'
                    />
                  </StackItem>
                </>
              ) : (
                <StackItem>
                  Tabela de preços <SelectTabelaPrecos />
                </StackItem>
              )}
              <StackItem>
                Forma de pagamento <SelectFormaPagamento />
              </StackItem>
              <StackItem></StackItem>
            </Stack>
          </CardBody>
          <CardFooter>
            <Split>
              <SplitItem isFilled />
              <SplitItem>
                Valor total do atendimento:{' '}
                <TextInput
                  value={'R$ ' + formatBRL(IntegerToBRL(relatorio?.valor_total_atendimento || 0))}
                  type='text'
                  aria-label='total_pecas'
                  id='id_total_pecas'
                  isDisabled
                  className='pf-u-my-sm'
                  style={{ maxWidth: '9rem' }}
                />
              </SplitItem>
            </Split>
          </CardFooter>
        </Card>
      </StackItem>
    </Stack>
  )
}

export default Cobranca
