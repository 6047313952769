import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Checkbox,
  DataList,
  DataListCell,
  DataListItem,
  DataListItemCells,
  DataListItemRow,
  HelperText,
  HelperTextItem,
  Modal,
  ModalVariant,
  Split,
  SplitItem,
  TextArea,
  TextInput,
} from '@patternfly/react-core'
import { ReactNode, useContext, useEffect, useState } from 'react'
import { Cliente } from '../../clients'
import GlobalContext from '../../contexts/GlobalContext'
import Select from '../shared/Select'
import ValidationAlert from '../shared/ValidationAlert'
interface IProps {
  isModalOpen: boolean
  handleModalToggle: () => void
  handleSubmit: (newCliente: Cliente) => void
  cliente?: Cliente
}

const DataListItemForm = ({
  label,
  children,
  helperText,
}: {
  label: string
  children: ReactNode
  helperText?: string
}) => (
  <DataListItem aria-labelledby={label}>
    <DataListItemRow>
      <DataListItemCells
        dataListCells={[
          <DataListCell key={label}>
            <span id={label}>{label}</span>
            <HelperText>
              <HelperTextItem variant='indeterminate'>{helperText ?? ''}</HelperTextItem>
            </HelperText>
          </DataListCell>,
          <DataListCell width={2} key={label}>
            {children}
          </DataListCell>,
        ]}
      />
    </DataListItemRow>
  </DataListItem>
)

const ModalCreateCliente = ({ isModalOpen, handleModalToggle, handleSubmit, cliente }: IProps) => {
  const { isLoading, precos, formaPagamentos } = useContext(GlobalContext)
  const isCreate = cliente === undefined
  const title = `${isCreate ? 'Criar' : 'Editar'} Cliente`

  const [nomeCliente, setNomeCliente] = useState(isCreate ? '' : cliente?.nome)
  const [nome_exibicao, setNomeExib] = useState(isCreate ? '' : cliente?.nome_exibicao)
  const [email_to, setEmailTo] = useState(isCreate ? '' : cliente?.email_to)
  const [email_cc, setEmailCC] = useState(isCreate ? '' : cliente?.email_cc)
  const [tabela_precos_id, setTabPrecosId] = useState(isCreate ? 1 : cliente?.tabela_precos_id)
  const [instrucoes_cobranca, setInstruCobranca] = useState(
    isCreate ? '' : cliente?.instrucoes_cobranca
  )
  const [forma_pagamento_id, setPagamentoId] = useState(isCreate ? 1 : cliente?.forma_pagamento_id)
  const [exibir_valores, setExibirValores] = useState(isCreate ? true : cliente?.exibir_valores)
  const [is_contrato, setIsContrato] = useState(isCreate ? false : cliente?.is_contrato)

  const [toClean, setToClean] = useState(true)
  const [alertsToggle, setAlertsToggle] = useState(false)
  const allFilled = [nomeCliente, nome_exibicao, email_to, instrucoes_cobranca].every(e => e !== '')

  useEffect(() => {
    if (isLoading) return
    setNomeCliente(cliente?.nome ?? '')
    setNomeExib(cliente?.nome_exibicao ?? '')
    setEmailTo(cliente?.email_to ?? '')
    setEmailCC(cliente?.email_cc ?? '')
    setTabPrecosId(cliente?.tabela_precos_id ?? 1)
    setInstruCobranca(cliente?.instrucoes_cobranca ?? '')
    setPagamentoId(cliente?.forma_pagamento_id ?? 1)
    setExibirValores(cliente?.exibir_valores ?? true)
    setIsContrato(cliente?.is_contrato ?? false)
  }, [cliente, toClean])

  const innerHandleSubmit = () => {
    if (!allFilled) return setAlertsToggle(true)

    handleSubmit({
      nome: nomeCliente,
      nome_exibicao,
      email_to,
      email_cc,
      tabela_precos_id,
      instrucoes_cobranca,
      forma_pagamento_id,
      exibir_valores,
      is_contrato,
    })
    setToClean(!toClean)
  }

  return (
    <Modal
      variant={ModalVariant.large}
      title={title}
      aria-label='modal delete'
      isOpen={isModalOpen}
      onClose={handleModalToggle}
      actions={[
        <Button
          key='create'
          variant='primary'
          form='modal-with-form-form'
          onClick={innerHandleSubmit}
        >
          Salvar
        </Button>,
        <Button key='cancelar' variant='secondary' isDanger onClick={handleModalToggle}>
          Cancelar
        </Button>,
      ]}
    >
      <Card isCompact className='pf-u-my-sm'>
        <CardTitle>Interno</CardTitle>
        <CardBody>
          <HelperText>
            <HelperTextItem variant='indeterminate'>
              Informações para controle interno DrSYS, não aparece diretamente no relatório.
            </HelperTextItem>
          </HelperText>
          <DataList isCompact aria-label='cliente data list publico'>
            <DataListItemForm
              label='Nome do Cliente / Nome de Busca'
              helperText='Nome interno com informações adicionais para auxiliar na busca do cliente'
            >
              <TextInput
                placeholder='Nome do Cliente'
                value={nomeCliente}
                type='text'
                aria-label='nome_do_cliente'
                onChange={e => {
                  setNomeCliente(e)
                  setAlertsToggle(false)
                }}
              />
            </DataListItemForm>
            <DataListItemForm
              label='Observações para técnico preencher cobrança'
              helperText='Observações adicionais para auxiliar o técnico a preencher os campos de cobrança'
            >
              <TextArea
                placeholder='Observações para técnico preencher cobrança'
                value={instrucoes_cobranca}
                type='text'
                aria-label='instru_cob'
                onChange={e => {
                  setInstruCobranca(e)
                  setAlertsToggle(false)
                }}
              />
            </DataListItemForm>
            <DataListItemForm label=''>
              <Split>
                <SplitItem>
                  <Checkbox
                    label='Exibir valores monetários no relatório'
                    isChecked={exibir_valores}
                    onChange={setExibirValores}
                    id='exibir-valores'
                    name='exibir-valores-cliente'
                    className='pf-u-m-md pf-u-my-md-on-md'
                  />
                </SplitItem>
                <SplitItem>
                  <Checkbox
                    label='Cliente tem contrato com a DrSYS'
                    isChecked={is_contrato}
                    onChange={setIsContrato}
                    id='tem-contrato'
                    name='tem-contrato-cliente'
                    className='pf-u-m-md pf-u-my-md-on-md'
                  />
                </SplitItem>
              </Split>
            </DataListItemForm>
          </DataList>
        </CardBody>
      </Card>

      <Card isCompact className='pf-u-my-sm'>
        <CardTitle>Publico</CardTitle>
        <CardBody>
          <HelperText>
            <HelperTextItem variant='indeterminate'>
              Informações que serão exibidas no relatório.
            </HelperTextItem>
          </HelperText>
          <DataList isCompact aria-label='cliente data list publico'>
            <DataListItemForm
              label='Nome de Exibição'
              helperText='Nome do cliente que será exibido no relatório'
            >
              <TextInput
                placeholder='Nome de Exibição'
                value={nome_exibicao}
                type='text'
                aria-label='nome_exibicao_cliente'
                onChange={e => {
                  setNomeExib(e)
                  setAlertsToggle(false)
                }}
              />
            </DataListItemForm>
            <DataListItemForm label='Email Destino'>
              <TextInput
                placeholder='Email To'
                value={email_to}
                type='text'
                aria-label='email_to'
                onChange={e => {
                  setEmailTo(e)
                  setAlertsToggle(false)
                }}
              />
            </DataListItemForm>
            <DataListItemForm label='Email em Cópia'>
              <TextInput
                placeholder='Email CC'
                value={email_cc}
                type='text'
                aria-label='email_cc'
                onChange={e => {
                  setEmailCC(e)
                  setAlertsToggle(false)
                }}
              />
            </DataListItemForm>
            <DataListItemForm
              label='Tabela de Preços'
              helperText='
                    Tabela de preços que será utilizada para o cliente,
                    se tabela de preços tiver uma url associada, 
                    será exibido um link no corpo do relatório'
            >
              <Select
                ouiaId={'TabelaDePrecosSelectionOuiaId'}
                toggleId={'TabelaDePrecosSelectionToggleId'}
                startSelected={tabela_precos_id}
                onChange={setTabPrecosId}
                list={Array.from(precos).map(p => {
                  return { label: p[1].nome, value: p[1].id }
                })}
              />
            </DataListItemForm>
            <DataListItemForm label='Forma de pagamento'>
              <Select
                ouiaId={'FormaDePagamentoSelectionOuiaId'}
                toggleId={'FormaDePagamentoSelectionToggleId'}
                startSelected={forma_pagamento_id}
                onChange={setPagamentoId}
                list={Array.from(formaPagamentos).map(p => {
                  return { label: p[1].nome, value: p[1].id }
                })}
              />
            </DataListItemForm>
          </DataList>
        </CardBody>
      </Card>
      {!allFilled && alertsToggle && <ValidationAlert message='Preencha todos os campos' />}
    </Modal>
  )
}

export default ModalCreateCliente
