import {
  Bullseye,
  Button,
  Flex,
  FlexItem,
  Grid,
  GridItem,
  Spinner,
  Split,
  SplitItem,
  Stack,
  StackItem,
} from '@patternfly/react-core'
import {
  ArrowCircleDownIcon,
  ArrowCircleUpIcon,
  EyeIcon,
  UploadIcon,
} from '@patternfly/react-icons'
import { useContext, useState } from 'react'
import GlobalContext from '../../contexts/GlobalContext'
import { formatNumeroRelat } from '../../utils/misc'
import PageFrame from '../shared/PageFrame'
import Anexos from './Anexos'
import ApoioRemoto from './ApoioRemoto'
import Cobranca from './Cobranca'
import Contato from './Contato'
import DescricaoServico from './DescricaoServico'
import EnviarParaCliente from './EnviarParaCliente'
import ModalVisualizar from './ModalVisualizar'
import MotivoAtendimento from './MotivoAtendimento'
import SelectCliente from './SelectCliente'
import SelectLocal from './SelectLocal'
import Context, { Provider } from './store/Context'
import ValoresPecas from './ValoresPecas'
import EnviarParaRevisao from './EnviarParaRevisao'

const EditorLayout = () => {
  const { isLoading, handleSaveRelat, relatorio } = useContext(Context)
  const { clientes, me } = useContext(GlobalContext)

  const [isModalVisualizarOpen, setIsModalVisualizarOpen] = useState(false)

  if (isLoading)
    return (
      <Bullseye>
        <Spinner isSVG size='xl' aria-label='carregando' />
      </Bullseye>
    )

  let tamanhoEmail =
    !relatorio.anexos || relatorio.anexos.length === 0
      ? 100
      : relatorio.anexos
          .map(r => r.size)
          .reduce((a, b) => {
            if (!a) return b
            if (!b) return a
            return a + b
          }, 0)
  if (!tamanhoEmail) {
    tamanhoEmail = 100
  }
  const cliente = clientes.get(relatorio.cliente_id || -1)
  const nomeCliente = cliente ? cliente.nome_exibicao : 'Escolha o cliente'
  let dataRelat
  if (relatorio.hora_inicio) {
    const dt = new Date(relatorio.hora_inicio)
    dataRelat = `${dt.getDate()}/${dt.getMonth() + 1}/${dt.getFullYear()}`
  } else {
    dataRelat = ''
  }

  return (
    <Stack hasGutter>
      <StackItem>
        <Split>
          <SplitItem isFilled />
          <SplitItem id='top-of-the-page'>
            <b>
              Relat. {nomeCliente} {dataRelat} {me.current_user.sigla_tecnico}
              {formatNumeroRelat(relatorio)}
            </b>
          </SplitItem>
          <SplitItem isFilled />
        </Split>
      </StackItem>

      <StackItem>
        <Flex justifyContent={{ default: 'justifyContentSpaceBetween' }}>
          <FlexItem>
            {me.current_user.sigla_tecnico}
            {formatNumeroRelat(relatorio)}
          </FlexItem>
          <FlexItem>
            <Button variant='primary'>Auto Save ON</Button>
            <Button variant='control' onClick={handleSaveRelat}>
              Salvar <UploadIcon />
            </Button>
            <Button variant='control' onClick={() => setIsModalVisualizarOpen(true)}>
              Visualizar <EyeIcon />
            </Button>
            <a href='#bottom-of-the-page'>
              <Button variant='control' className='pf-u-mx-sm'>
                <ArrowCircleDownIcon />
              </Button>
            </a>
          </FlexItem>
        </Flex>
      </StackItem>

      <StackItem>
        <Split hasGutter isWrappable>
          <SplitItem isFilled>
            <SelectCliente />
          </SplitItem>
          <SplitItem isFilled>
            <SelectLocal />
          </SplitItem>
          <SplitItem isFilled>
            <ApoioRemoto />
          </SplitItem>
        </Split>
      </StackItem>

      <StackItem>
        <Contato />
      </StackItem>

      <StackItem>
        <MotivoAtendimento />
      </StackItem>

      <StackItem>
        <DescricaoServico />
      </StackItem>

      <StackItem>
        <ValoresPecas />
      </StackItem>

      <StackItem>
        <Anexos />
      </StackItem>
      <StackItem>Tamanho total do e-mail: {(tamanhoEmail / 1000000).toFixed(2)}MB</StackItem>

      <StackItem>
        <Cobranca />
      </StackItem>

      <Split isWrappable>
        <SplitItem>
          <Button variant='control' onClick={handleSaveRelat}>
            Salvar <UploadIcon />
          </Button>
        </SplitItem>
        <SplitItem isFilled>
          <Button variant='control' onClick={() => setIsModalVisualizarOpen(true)}>
            Visualizar <EyeIcon />
          </Button>
        </SplitItem>
        <SplitItem>
          <a href='#top-of-the-page'>
            <Button variant='control' className='pf-u-mx-sm'>
              <ArrowCircleUpIcon id='bottom-of-the-page' />
            </Button>
          </a>
        </SplitItem>
      </Split>
      <StackItem>
        <Split hasGutter>
          <SplitItem>
            <EnviarParaCliente />
          </SplitItem>
          <SplitItem isFilled>
            <EnviarParaRevisao />
          </SplitItem>
        </Split>
      </StackItem>
      <ModalVisualizar
        isModalOpen={isModalVisualizarOpen}
        setIsModalOpen={setIsModalVisualizarOpen}
      />
    </Stack>
  )
}

const EditorPage = () => {
  const { isLoading } = useContext(Context)
  return (
    <PageFrame pageName='Editor' isLoading={isLoading}>
      <Grid hasGutter>
        <GridItem md={1} lg={1} xl={3} />
        <GridItem sm={12} md={10} lg={10} xl={6}>
          <EditorLayout />
        </GridItem>
        <GridItem md={1} lg={1} xl={3} />
      </Grid>
    </PageFrame>
  )
}

const Editor = () => {
  return (
    <Provider>
      <EditorPage />
    </Provider>
  )
}

export default Editor
